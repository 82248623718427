import { template as template_714daab4bb1e4c0fb83b213806f18bd8 } from "@ember/template-compiler";
const FKControlMenuContainer = template_714daab4bb1e4c0fb83b213806f18bd8(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
