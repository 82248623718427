import { template as template_057e7e0a3da24b8e95f0b9ce3cf3cd64 } from "@ember/template-compiler";
const SidebarSectionMessage = template_057e7e0a3da24b8e95f0b9ce3cf3cd64(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
