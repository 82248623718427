import { template as template_95443e8c4801406592f206f4bdd782d5 } from "@ember/template-compiler";
import bodyClass from "discourse/helpers/body-class";
const AddTopicStatusClasses = template_95443e8c4801406592f206f4bdd782d5(`
  {{#if @topic.invisible}}
    {{bodyClass "topic-status-unlisted"}}
  {{/if}}
  {{#if @topic.pinned}}
    {{bodyClass "topic-status-pinned"}}
  {{/if}}
  {{#if @topic.unpinned}}
    {{bodyClass "topic-status-unpinned"}}
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AddTopicStatusClasses;
