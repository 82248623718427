import { template as template_c9b8393c8bfa4b838f03f2056547c249 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_c9b8393c8bfa4b838f03f2056547c249(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
