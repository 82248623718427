import { template as template_1ae344b35af24b5c86ad8a7edcc951fb } from "@ember/template-compiler";
const FKLabel = template_1ae344b35af24b5c86ad8a7edcc951fb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
