import { template as template_3898fab1e841412fb35edfe388162158 } from "@ember/template-compiler";
const WelcomeHeader = template_3898fab1e841412fb35edfe388162158(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
